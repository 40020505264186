import React from "react";
import Modal from "../../../components/Modals";

const ModalShowFile = ({ actionModal, openModal, entity }) => {
    const linkDocument = (entity?.url + `/open/freight/cte/${entity?.identifier}/view`).replace("//open", "/open");
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-12/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>Arquivo CTE {entity?.cteNumber}</h5>
                </div>
            }
        >
            <iframe title="CTE file" width="100%" height="700em" src={linkDocument} allowFullScreen={true}></iframe>
        </Modal>
    );
};

export default ModalShowFile;
